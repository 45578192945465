import React, { FC, useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { RootState } from "../ducks";

const MapController: FC = () => {
  const map = useMap();
  const { requestedLatLngBounds } = useSelector(
    (root: RootState) => root.controls
  );

  useEffect(() => {
    requestedLatLngBounds && map.flyToBounds(requestedLatLngBounds);
  }, [requestedLatLngBounds]);

  return null;
};

export default MapController;
