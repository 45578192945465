import React, { FC } from "react";

const Select: FC<{
  name: string;
  label?: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}> = ({ name, label, handleChange, children, disabled }) => {
  return (
    <label className="block mb-2">
      {label && <span className="text-gray-700">{label}</span>}
      <select
        name={name}
        className={`block w-full mt-1 rounded-md border-gray-300 shadow-sm 
          focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
            disabled ? "cursor-not-allowed" : ""
          }`}
        onChange={handleChange}
        disabled={disabled}
        defaultValue=""
      >
        {children}
      </select>
    </label>
  );
};

export { Select };
