export const API_ENDPOINT_BASE_URL =
  process.env?.REACT_APP_API_ENDPOINT_BASE_URL ?? "http://localhost:5000";
export const API_ENDPOINT_VERSION =
  process.env?.REACT_APP_API_ENDPOINT_VERSION ?? "v1";
export const DEV_REDUX_MIDDLEWARE_CHECKS_OFF =
  !!process.env.REACT_APP_DEV_REDUX_MIDDLEWARE_CHECKS_OFF;

export const ZIP_CODE_FEATURE_PROPERTY_KEY = "zc";

export const CSV_FILE_NAME = "coverage-areas.csv";

export const TO_EMAIL =
  process.env?.REACT_APP_TO_EMAIL?.trim() || "sb@webspence.com";
