import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../ducks";
import controls from "../ducks/controls";
import { MY_COVERAGE_AREAS_PATH } from "../router";
import { Select } from "./elements";
import qs from "query-string";
import { API_ENDPOINT_BASE_URL } from "../constants";
import { useFetchZipGeoJson } from "../hooks/fetch";
import Button from "./button";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { SearchByZipCodeValues, ZipCode } from "../types";

const RegionSelector = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedState, selectedCounty, selectedZipCode, searchBy } =
    useSelector((state: RootState) => state.controls);

  const { states } = useFetchStates(`${API_ENDPOINT_BASE_URL}/v1/states/`);

  const { counties } = useFetchCounties(
    `${API_ENDPOINT_BASE_URL}/v1/states/${selectedState}/`,
    selectedState
  );

  const { fetchByCounty } = useFetchZipGeoJson();

  // Update the query string in the url
  useEffect(() => {
    const queryString = qs.stringify({
      state: selectedState,
      county: selectedCounty,
      zip: selectedZipCode,
    });
    history.push(`${MY_COVERAGE_AREAS_PATH}?${queryString}`);
  }, [selectedState, selectedCounty, selectedZipCode]);

  const handleStateChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void = (e) => {
    const state = e.target.value; // TODO: Validate
    dispatch(controls.actions.setSelectedState(state));
    dispatch(controls.actions.setSelectedCounty(""));
  };

  const handleCountyChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void = (e) => {
    const county = e.target.value; // TODO: Validate
    dispatch(controls.actions.setSelectedCounty(county));
    selectedState && county && fetchByCounty({ state: selectedState, county });
  };

  return (
    <section className="px-4 pt-2 pb-4 overflow-auto">
      <nav className="pb-2">
        <ul className="flex gap-4">
          <Button
            selected={searchBy === "zip"}
            onClick={() => dispatch(controls.actions.setSearchBy("zip"))}
          >
            Zip Code
          </Button>
          <Button
            selected={searchBy === "county"}
            onClick={() => dispatch(controls.actions.setSearchBy("county"))}
          >
            County
          </Button>
        </ul>
      </nav>
      {searchBy === "county" && (
        <div>
          <Select name="state" handleChange={handleStateChange}>
            <option key="default" value="">
              Select a state
            </option>
            {states &&
              states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
          </Select>
          <Select
            name="state"
            handleChange={handleCountyChange}
            disabled={!selectedState}
          >
            <option key="default" value="">
              {selectedState ? "Select a county" : "Select a state first"}
            </option>
            {counties.length > 0 &&
              counties.map((county) => (
                <option key={county} value={county}>
                  {county}
                </option>
              ))}
          </Select>
          <p className="text-sm pt-2 text-gray-700">
            Searching adds all zip codes in the selected county to the map.
          </p>
        </div>
      )}
      {searchBy === "zip" && <SearchByZipCode />}
    </section>
  );
};

const SearchByZipCode: FC = () => {
  const dispatch = useDispatch();

  const { fetchByZipCode } = useFetchZipGeoJson();

  const handleZipCodeChange = (zipCode: ZipCode) => {
    dispatch(controls.actions.setSelectedZipCode(zipCode));
    fetchByZipCode(zipCode);
  };

  return (
    <Formik
      initialValues={{ zipCode: "" }}
      onSubmit={(
        values: SearchByZipCodeValues,
        { setSubmitting }: FormikHelpers<SearchByZipCodeValues>
      ) => {
        values.zipCode && handleZipCodeChange(values.zipCode);
        setSubmitting(false);
      }}
    >
      <Form>
        <Field
          type="text"
          name="zipCode"
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm 
    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="Zip code"
        />
        <div className="py-2">
          <Button type="submit">Search</Button>
        </div>
        <p className="text-sm text-gray-700">
          Searching by zip code first looks up the zip code's county and then
          adds all zip codes in that county to the map.
        </p>
      </Form>
    </Formik>
  );
};

const useFetchStates = (endpoint: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data: { states: { name: string }[] }) => {
        const states = data.states.map((state) => state.name);
        states.sort();
        setStates(states);
      })
      .catch((e) => {
        setError(e);
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    error,
    states,
  };
};

const useFetchCounties = (endpoint: string, state: string | undefined) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [counties, setCounties] = useState<string[]>([]);

  useEffect(() => {
    if (state) {
      fetch(endpoint)
        .then((response) => response.json())
        .then((data: { counties: { county: string }[] }) => {
          const counties = data.counties.map((county) => county.county);
          counties.sort();
          setCounties(counties);
          console.log({ counties });
        })
        .catch((e) => {
          setError(e);
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [state]);

  return {
    loading,
    error,
    counties,
  };
};

export default RegionSelector;
