import React from "react";
import RegionSelector from "./region-selector";
import Settings from "./settings";
import { Tooltip } from "./tooltip";

const Controls = () => {
  return (
    <div className="h-full flex flex-col rounded-lg border-2 border-gray-200 shadow divide-y overflow-hidden bg-white">
      <div className="relative inline">
        <h2 className="text-lg text-center p-4">
          <Tooltip
            tip="Step 1, search by zip code or county to add zip codes to the map"
            position="left"
            icon="1"
          >
            Search
          </Tooltip>
        </h2>
      </div>
      <RegionSelector />
      <Settings />
    </div>
  );
};

export default Controls;
