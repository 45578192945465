import React, { FC } from "react";
import { ButtonHTMLAttributes } from "react";

const Button: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    selected?: boolean;
    onClick?: (a: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    bgColor?: string;
    color?: string;
  }
> = ({ children, selected, onClick, bgColor, color, type, ...props }) => {
  return (
    <button
      type={type}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      className={`border border-gray-300 rounded-md px-4 ${
        selected ? "ring ring-jmg-blue" : ""
      } ${bgColor} ${color}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
