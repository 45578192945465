import React from "react";
import { ReactNode } from "react";
import { FC } from "react";

export const Tooltip: FC<{
  tip: string;
  width?: string;
  position?: "right" | "left" | "top-left";
  icon?: string | ReactNode;
}> = ({ tip, width, position = "right", icon, children }) => {
  return (
    <div className="tooltip relative inline-block">
      {children}
      <div className="group absolute -top-2 -right-2">
        <div className="relative">
          <span className={`flex ${icon ? "h-4 w-4" : "h-3 w-3"}`}>
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-jmg-green opacity-75"></span>
            <span
              className={`relative inline-flex rounded-full ${
                icon ? "h-4 w-4" : "h-3 w-3"
              } bg-jmg-green text-xs text-center justify-center items-center text-white`}
            >
              {icon}
            </span>
          </span>
          <p
            className={`absolute hidden right-0 left-0 rounded-lg z-50 bg-gray-600 p-2 text-white text-sm group-hover:block`}
            style={{
              width: width ? width : "150px",
              transform:
                position === "left" || "top-left" ? "translateX(-95%)" : "",
              bottom: position === "top-left" ? 0 : undefined,
            }}
          >
            {tip}
          </p>
        </div>
      </div>
    </div>
  );
};
