import React, { FC, useEffect } from "react";
import qs from "query-string";
import { useDispatch } from "react-redux";
import controls from "../ducks/controls";
import { useLocation } from "react-router";
import { useFetchZipGeoJson } from "../hooks/fetch";

/**
 * Injects logic to read from the url and set the selected regions
 * @returns
 */
const Init: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { fetchByZipCode, fetchByCounty } = useFetchZipGeoJson();

  useEffect(() => {
    const parsed = qs.parse(location.search);

    const state = parsed.state ? String(parsed.state) : undefined;
    const county = parsed.county ? String(parsed.county) : undefined;
    const zip = parsed.zip ? String(parsed.zip) : undefined;

    dispatch(controls.actions.setSelectedState(state));
    dispatch(controls.actions.setSelectedCounty(county));
    dispatch(controls.actions.setSelectedZipCode(zip));

    if (zip) {
      fetchByZipCode(zip);
    } else if (state && county) {
      fetchByCounty({ state, county });
    }
  }, []);
  return <></>;
};

export default Init;
