import { useEffect, useState } from "react";
import { GeoJSON as GeoJSONType } from "geojson";
import { API_ENDPOINT_BASE_URL, API_ENDPOINT_VERSION } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import features from "../ducks/features";
import { GeoPoliticalFeatureCollection, ZipCode } from "../types";
import { RootState } from "../ducks";
import controls from "../ducks/controls";
import { calcAggregateCoordinates } from "../util";

interface Handlers<T> {
  handleResponse: (response: T) => void;
  handleError: (error: any) => void;
  cleanup: () => void;
}

export function fetchHandler<U = any>(
  urlPath: string,
  handlers: Handlers<U>,
  options?: RequestInit
) {
  return fetch(
    `${API_ENDPOINT_BASE_URL}/${API_ENDPOINT_VERSION}/${urlPath}`,
    options
  )
    .then((response) => response.json())
    .then((parsedData) => {
      console.log({ parsedData });
      handlers.handleResponse(parsedData);
    })
    .catch((e) => {
      handlers.handleError(e);
      console.error(e);
    })
    .finally(() => {
      handlers.cleanup();
    });
}

export function useFetch<T, U = any>(
  urlPath?: string,
  method?: "GET" | "POST",
  body?: BodyInit | null | undefined
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    fetchHandler(
      `${urlPath}`,
      {
        handleResponse: setData,
        handleError: setError,
        cleanup: () => setLoading(false),
      },
      { method, body: body }
    );
  }, []);

  return {
    loading,
    error,
    data,
  };
}

export const useFetchZipGeoJson = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const fetchCollection = (url: string) => {
    setLoading(true);

    fetchHandler<GeoPoliticalFeatureCollection>(url, {
      handleResponse: (collection) => {
        const { latLngBounds } = calcAggregateCoordinates(collection);
        dispatch(controls.actions.setRequestedCoordinates(latLngBounds));
        dispatch(features.actions.addFeatures(collection.features));
      },
      handleError: setError,
      cleanup: () => setLoading(false),
    });
  };

  const fetchByZipCode = (selectedZipCode: ZipCode) => {
    const url = `zips/${selectedZipCode}/?includeAllInCounty=1`;

    fetchCollection(url);
  };

  const fetchByCounty = ({
    state,
    county,
  }: {
    state: string;
    county: string;
  }) => {
    const url = `zips/?state=${state}&county=${county}`;

    fetchCollection(url);
  };

  return {
    fetchByZipCode,
    fetchByCounty,
    loading,
    error,
  };
};

export const useFetchSingleZipGeoJson = (zipCode?: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [geoJsonData, setGeoJsonData] = useState<GeoJSONType | null>(null);

  useEffect(() => {
    if (zipCode) {
      fetchHandler(`zips/${zipCode}/`, {
        handleResponse: setGeoJsonData,
        handleError: setError,
        cleanup: () => setLoading(false),
      });
    }
  }, [zipCode]);

  return {
    loading,
    error,
    geoJsonData,
  };
};
