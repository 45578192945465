import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../ducks";
import controls from "../ducks/controls";
import { ContactInfoForm } from "./contact-info-form";
import { Modal } from "./modal";

export const ContactFormPopup: FC = () => {
  const dispatch = useDispatch();
  const { name } = useSelector((root: RootState) => root.coverage);
  const { showEditName } = useSelector((root: RootState) => root.controls);

  const handleClose = () => {
    dispatch(controls.actions.setShowEditName(false));
  };

  return showEditName ? (
    <Modal title="Your Info" handleClose={handleClose} disabled={!name}>
      <ContactInfoForm />
    </Modal>
  ) : null;
};
