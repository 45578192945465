import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../ducks";
import coverage from "../ducks/coverage";
import useRegionMapping from "../hooks/useRegionMapping";
import CSVExporter from "./csv-exporter";
import { ReactComponent as EditIcon } from "../resources/edit.svg";
import controls from "../ducks/controls";
import { TO_EMAIL } from "../constants";
import { Tooltip } from "./tooltip";

const Results = () => {
  const { name } = useSelector((root: RootState) => root.coverage);
  const { coveredZipCodes, coveredCounties, coveredCities } =
    useRegionMapping();

  const dispatch = useDispatch();

  const removeZip = (zip: string) => {
    dispatch(coverage.actions.removeZipCode(zip));
  };

  const showEditName = () => {
    dispatch(controls.actions.setShowEditName(true));
  };

  return (
    <div className="h-full flex flex-col rounded-lg border-2 border-gray-200 shadow divide-y overflow-hidden bg-white">
      <h2 className="text-lg text-center p-4">My Coverage Areas</h2>
      <div className="overflow-auto pb-4">
        <section>
          <h3 className="p-2 pl-4 text-sm text-gray-500">My name</h3>
          <div className="flex gap-2 items-center">
            <p className="pl-8 py-2">{name ?? "Enter name"}</p>
            <EditIcon
              className="text-gray-500 h-4 w-4 cursor-pointer"
              onClick={showEditName}
            />
          </div>
        </section>
        <section>
          <h3 className="p-2 pl-4 text-sm text-gray-500">Zip codes</h3>
          <ul className="flex flex-row flex-wrap content-start px-8 py-2 overflow-auto min-h-12">
            {coveredZipCodes.map((zip) => (
              <li
                key={zip}
                className="px-2 font-mono flex-grow-0 border border-gray-300 rounded-md flex mr-1 mb-1"
              >
                <div className="pr-1 cursor-pointer">{zip}</div>
                <div
                  onClick={() => removeZip(zip)}
                  className="text-gray-300 cursor-pointer hover:text-red-600"
                >
                  x
                </div>
              </li>
            ))}
          </ul>
        </section>
        <section>
          <h3 className="p-2 pl-4 text-sm text-gray-500">Counties</h3>
          <ul className="flex flex-row flex-wrap content-start px-8 py-2 min-h-12">
            {coveredCounties.map((county) => (
              <li key={county} className="px-2 flex-grow-0">
                {county}
              </li>
            ))}
          </ul>
        </section>
        <section>
          <h3 className="p-2 pl-4 text-sm text-gray-500">Cities</h3>
          <ul className="flex flex-row flex-wrap content-start px-8 py-2 min-h-12">
            {coveredCities.map((city) => (
              <li key={city} className="px-2 flex-grow-0">
                {city}
              </li>
            ))}
          </ul>
        </section>
      </div>
      <section className="text-center py-4">
        <Tooltip tip="Step 3, download CSV" icon="3" position="left">
          <CSVExporter />
        </Tooltip>
        <div className="pt-2">
          Email CSV to{" "}
          <Tooltip
            tip={`Step 4, email CSV to ${TO_EMAIL}`}
            icon="4"
            position="top-left"
            width={"300px"}
          >
            <a
              target="_blank"
              href={encodeURI(
                `mailto:${TO_EMAIL}?subject=Coverage Areas for ${name}&body=Please see the attached spreadsheet for my coverage areas.`
              )}
              className="underline"
            >
              {TO_EMAIL}
            </a>
          </Tooltip>
        </div>
      </section>
    </div>
  );
};

export default Results;
