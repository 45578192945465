import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZipCode, County } from "../types";

interface CoverageInitialState {
  zipCodes: ZipCode[];
  name?: string;
}

const initialState: CoverageInitialState = {
  zipCodes: [],
};

const coverage = createSlice({
  name: "coverage",
  initialState,
  reducers: {
    addZipCode(state, action: PayloadAction<ZipCode>) {
      const zip = action.payload;
      if (!state.zipCodes.includes(zip)) {
        const zipCodes = state.zipCodes;
        zipCodes.push(zip);
        zipCodes.sort();
        state.zipCodes = zipCodes;
      }
    },
    removeZipCode(state, action: PayloadAction<ZipCode>) {
      const zip = action.payload;
      const index = state.zipCodes.indexOf(zip);
      if (index !== -1) {
        state.zipCodes.splice(index, 1);
      }
    },
    toggleZipCode(state, action: PayloadAction<ZipCode>) {
      const zip = action.payload;
      const index = state.zipCodes.indexOf(zip);
      if (index !== -1) {
        state.zipCodes.splice(index, 1);
      } else {
        state.zipCodes.push(zip);
      }
    },
    setName(state, action: PayloadAction<string | undefined>) {
      state.name = action.payload;
    },
  },
});

export default coverage;
