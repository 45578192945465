import React, { FC } from "react";
import { ReactComponent as XIcon } from "../resources/x.svg";

export const Modal: FC<{
  title: string;
  handleClose?: () => void;
  disabled?: boolean;
  width?: number;
}> = ({ title, handleClose, disabled, width = 400, children }) => {
  return (
    <div
      className="modal-background fixed w-screen h-screen top-0 right-0 flex justify-center items-center"
      style={{ zIndex: 9999 }}
    >
      <div className="bg-gray-700 opacity-90 absolute top-0 right-0 w-full h-full" />
      <div
        className="relative bg-white rounded-lg z-10 max-w-full"
        style={{ width: `${width}px` }}
      >
        <header className="border-b flex justify-center p-2">
          <h2 className="text-xl">{title}</h2>
          <div
            className={`absolute right-2 top-2 ${
              disabled ? "hidden" : "cursor-pointer"
            }`}
            onClick={disabled ? undefined : handleClose}
          >
            <XIcon />
          </div>
        </header>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};
