import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TO_EMAIL } from "../constants";
import { RootState } from "../ducks";
import controls from "../ducks/controls";
import { Modal } from "./modal";

export const HelpPopup: FC = () => {
  const dispatch = useDispatch();
  const { showHelp } = useSelector((root: RootState) => root.controls);

  const handleClose = () => {
    dispatch(controls.actions.setShowHelp(false));
  };

  return showHelp ? (
    <Modal title="Help" handleClose={handleClose} width={500}>
      <div className="px-4">
        <section>
          <h2 className="text-lg font-bold">Instructions</h2>
          <p>Complete the following:</p>
          <ol className="list-decimal pl-4">
            <li>Search by zip code or county to add zip codes to the map</li>
            <li>Select zip codes on the map</li>
            <li>Download CSV</li>
            <li>Email CSV to {TO_EMAIL}</li>
          </ol>
        </section>
        <section className="pt-4">
          <h2 className="text-lg font-bold">FAQ</h2>
          <ul className="flex flex-col gap-2">
            <li>
              <div className="font-semibold">What is "Free select mode"?</div>
              <div className="italic">
                Free select mode allows you to select zip codes on the map by
                simply hovering over a zip code.
              </div>
            </li>
            <li>
              <div className="font-semibold">
                What should the email subject be?
              </div>
              <div className="italic">Coverage Areas for {"<your name>"}.</div>
            </li>{" "}
            <li>
              <div className="font-semibold">
                How are counties and cities added to my coverage areas?
              </div>
              <div className="italic">
                Counties and cities are calculated based on selected zip codes.
                You cannot directly add a county or city.
              </div>
            </li>
            <li>
              <div className="font-semibold">
                Can a zip code be in one or more counties or cities?
              </div>
              <div className="italic">Yes, this is possible.</div>
            </li>
          </ul>
        </section>
      </div>
    </Modal>
  ) : null;
};
