import { CoverageCSVDataRow, GeoPoliticalFeatureCollection } from "./types";
import { Polygon, Position } from "geojson";
import { LatLngBoundsExpression } from "leaflet";

export const createCSVData = ({
  coveredZipCodes,
  coveredCounties,
  coveredCities,
}: {
  coveredZipCodes: string[];
  coveredCounties: string[];
  coveredCities: string[];
}): CoverageCSVDataRow[] => {
  let maxLength = Math.max(
    coveredZipCodes.length,
    coveredCounties.length,
    coveredCities.length
  );

  const data: CoverageCSVDataRow[] = [];
  for (let x = 0; x < maxLength; x++) {
    data.push({
      zip: coveredZipCodes[x],
      county: coveredCounties[x],
      city: coveredCities[x],
    });
  }

  return data;
};

/**
 * Longitude ranges from -180 to 180 degrees
 * Latitude ranges from -90 to 90 degrees
 * @see https://datatracker.ietf.org/doc/html/rfc7946#section-4
 * @see https://en.wikipedia.org/wiki/Decimal_degrees
 */
export const calcAggregateCoordinates = (
  collection: GeoPoliticalFeatureCollection
) => {
  const features = collection.features.filter((feature) => !!feature);

  // Get the exterior rings (@see https://datatracker.ietf.org/doc/html/rfc7946#appendix-A.3) of each feature
  const exteriorRings: Position[][] = features
    .map((feature) => {
      return feature.geometry.type === "Polygon" // TODO: May want to include all types
        ? (feature.geometry as Polygon).coordinates[0]
        : null;
    })
    .filter((x) => x !== null) as Position[][];

  const coordinates: Position[] = ([] as Position[]).concat(...exteriorRings);
  const fistCoordinate = coordinates.slice(0, 1)[0];

  // Determine max and min longitude and latitude
  const [lng, lat] = fistCoordinate;

  let maxLng = lng;
  let minLng = lng;
  let maxLat = lat;
  let minLat = lat;

  coordinates.forEach(([lng, lat]) => {
    if (lng > maxLng) {
      maxLng = lng;
    } else if (lng < minLng) {
      minLng = lng;
    }
    if (lat > maxLat) {
      maxLat = lat;
    } else if (lat < minLat) {
      minLat = lat;
    }
  });

  // Calculate center point
  const center = { lng: (maxLng + minLng) / 2, lat: (maxLat + minLat) / 2 };
  const latLngBounds: LatLngBoundsExpression = [
    [minLat, minLng],
    [maxLat, maxLng],
  ];
  return {
    latLngBounds,
    center,
  };
};

export const formatName = (name: string) => {
  const formattedName = name.trim().toLowerCase().replace(" ", "-");
  return formattedName;
};
