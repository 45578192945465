import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LatLngBoundsExpression } from "leaflet";
import { SearchByType } from "../types";

interface ControlsInitialState {
  selectedState?: string;
  selectedCounty?: string;
  selectedZipCode?: string;
  freeSelectMode?: boolean;
  searchBy: SearchByType;
  requestedLatLngBounds?: LatLngBoundsExpression;
  showEditName: boolean;
  showHelp: boolean;
}

const initialState: ControlsInitialState = {
  searchBy: "zip",
  showEditName: true,
  showHelp: false,
};

const controls = createSlice({
  name: "controls",
  initialState,
  reducers: {
    setSelectedState(state, action: PayloadAction<string | undefined>) {
      state.selectedState = action.payload;
    },
    setSelectedCounty(state, action: PayloadAction<string | undefined>) {
      state.selectedCounty = action.payload;
    },
    setSelectedZipCode(state, action: PayloadAction<string | undefined>) {
      state.selectedZipCode = action.payload;
    },
    setFreeSelectMode(state, action: PayloadAction<boolean>) {
      state.freeSelectMode = !!action.payload;
    },
    setSearchBy(state, action: PayloadAction<SearchByType>) {
      state.searchBy = action.payload;
    },
    setRequestedCoordinates(
      state,
      action: PayloadAction<LatLngBoundsExpression>
    ) {
      state.requestedLatLngBounds = action.payload;
    },
    setShowEditName(state, action: PayloadAction<boolean>) {
      state.showEditName = action.payload;
    },
    setShowHelp(state, action: PayloadAction<boolean>) {
      state.showHelp = action.payload;
    },
  },
});

export default controls;
