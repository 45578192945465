import { configureStore } from "@reduxjs/toolkit";
import { DEV_REDUX_MIDDLEWARE_CHECKS_OFF } from "./constants";
import rootReducer from "./ducks";

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: !DEV_REDUX_MIDDLEWARE_CHECKS_OFF,
      immutableCheck: !DEV_REDUX_MIDDLEWARE_CHECKS_OFF,
    }),
});
