import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZIP_CODE_FEATURE_PROPERTY_KEY } from "../constants";
import { GeoPoliticalFeature } from "../types";

interface FeaturesInitialState {
  zipCodeFeatures: { [key: string]: GeoPoliticalFeature };
}

const initialState: FeaturesInitialState = {
  zipCodeFeatures: {},
};

const features = createSlice({
  name: "features",
  initialState,
  reducers: {
    addFeatures(state, action: PayloadAction<GeoPoliticalFeature[]>) {
      const features = action.payload;
      features.forEach((feature) => {
        if (feature !== null) {
          const zipCode: string =
            feature?.properties?.[ZIP_CODE_FEATURE_PROPERTY_KEY];
          if (zipCode) {
            state.zipCodeFeatures[zipCode] = feature;
          }
        }
      });
    },
  },
});

export default features;
