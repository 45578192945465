import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../ducks";
import controls from "../ducks/controls";
import coverage from "../ducks/coverage";
import { ContactInfoValues } from "../types";
import Button from "./button";

export const ContactInfoForm: FC = () => {
  const dispatch = useDispatch();

  const { name } = useSelector((root: RootState) => root.coverage);

  const handleNameChange = (name: string) => {
    dispatch(coverage.actions.setName(name));
    dispatch(controls.actions.setShowEditName(false));
  };

  return (
    <Formik
      initialValues={{ name: name ?? "" }}
      onSubmit={(
        values: ContactInfoValues,
        { setSubmitting }: FormikHelpers<ContactInfoValues>
      ) => {
        values.name && handleNameChange(values.name);
        setSubmitting(false);
      }}
    >
      <Form>
        <p className="px-1 pb-1">
          Please enter your first and last name to get started!
        </p>
        <Field
          type="text"
          name="name"
          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm 
      focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          placeholder="First and last name"
        />
        <div className="py-2">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Formik>
  );
};
