import React, { FC, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { CSV_FILE_NAME } from "../constants";
import { RootState } from "../ducks";
import useRegionMapping from "../hooks/useRegionMapping";
import { createCSVData, formatName } from "../util";
import Button from "./button";

/**
 * Inspired by https://www.cluemediator.com/loading-data-asynchronously-and-download-csv-using-react-csv
 */
const CSVExporter: FC = () => {
  const { name } = useSelector((root: RootState) => root.coverage);
  const { coveredZipCodes, coveredCounties, coveredCities } =
    useRegionMapping();

  const coverageCSVData = createCSVData({
    coveredZipCodes,
    coveredCounties,
    coveredCities,
  });

  const filename = name
    ? `${formatName(name)}-${CSV_FILE_NAME}`
    : CSV_FILE_NAME;

  return (
    <>
      <CSVLink
        data={coverageCSVData}
        headers={CSVHeaders}
        filename={filename}
        target="_blank"
      >
        <Button bgColor="bg-jmg-blue" color="text-white">
          Download CSV
        </Button>
      </CSVLink>
    </>
  );
};

const CSVHeaders = [
  { label: "Zip Codes", key: "zip" },
  { label: "Counties", key: "county" },
  { label: "Cities", key: "city" },
];

export default CSVExporter;
