import React from "react";
import { useDispatch } from "react-redux";
import { ContactFormPopup } from "../components/contact-form-popup";
import Controls from "../components/controls";
import { HelpPopup } from "../components/help-popup";
import Map from "../components/map";
import Results from "../components/results";
import { Tooltip } from "../components/tooltip";
import controls from "../ducks/controls";
import { ReactComponent as ChevronUpIcon } from "../resources/chevron-up.svg";
import { ReactComponent as HelpCircleIcon } from "../resources/help-circle.svg";

function MyCoverageAreas() {
  const dispatch = useDispatch();

  const scrollTop = () => {
    window?.scroll({ top: 0, behavior: "smooth" });
  };

  const showHelp = () => {
    dispatch(controls.actions.setShowHelp(true));
  };

  return (
    <div className="lg:h-screen pt-2 flex flex-col bg-jmg-blue-dark">
      <div className="relative mx-2 mb-4 lg:mb-0 col-span-full rounded-lg border-2 border-gray-200 shadow text-center bg-white">
        <h1 className="p-2 h-12 text-center text-xl font-semibold">
          JMG Agent Coverage Map
        </h1>
        <div
          className="absolute top-2 right-2 cursor-pointer"
          onClick={showHelp}
        >
          <Tooltip tip="Click to view help screen" position="left">
            <div>
              <HelpCircleIcon />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="p-2 h-full flex flex-col-reverse lg:grid lg:grid-cols-3 lg:grid-rows-3 lg:gap-4 overflow-auto">
        <section className="relative col-span-2 row-span-3 mb-4 lg:mb-0 h-screen lg:h-full">
          <Map />
          <div className="absolute right-0 -top-4" style={{ zIndex: 9999 }}>
            <Tooltip
              tip="Step 2, select zip codes on the map"
              position="left"
              icon="2"
            />
          </div>
        </section>
        <section className="col-span-auto row-span-1 mb-4 lg:mb-0">
          <Controls />
        </section>
        <section className="col-span-auto row-span-2 mb-4 lg:mb-0">
          <Results />
        </section>
      </div>
      <button
        className="lg:hidden p-4 bg-blue-400 bg-opacity-40 text-white rounded-full fixed bottom-8 right-4"
        style={{ zIndex: 999 }}
        onClick={scrollTop}
      >
        <ChevronUpIcon />
      </button>
      <ContactFormPopup />
      <HelpPopup />
    </div>
  );
}

export default MyCoverageAreas;
