import React, { FC } from "react";
import { useDispatch } from "react-redux";
import controls from "../ducks/controls";

const Settings: FC = () => {
  const dispatch = useDispatch();

  const handleFreeSelectModeChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    const checked = e.target.checked; // TODO: Validate
    dispatch(controls.actions.setFreeSelectMode(checked));
  };

  return (
    <section className="px-4 pt-2 pb-4">
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-jmg-blue shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          onChange={handleFreeSelectModeChange}
        />
        <span className="ml-2">Free select mode</span>
      </label>
    </section>
  );
};

export default Settings;
