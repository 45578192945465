import {combineReducers} from "redux"
import controls from "./controls";
import coverage from "./coverage"
import features from "./features";

const rootReducer = combineReducers({
  coverage: coverage.reducer,
  controls: controls.reducer,
  features: features.reducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;