import { useSelector } from "react-redux";
import { RootState } from "../ducks";

const useRegionMapping = () => {
  const { zipCodeFeatures } = useSelector((root: RootState) => root.features);
  const { zipCodes: coveredZipCodes } = useSelector(
    (root: RootState) => root.coverage
  );

  const coveredCounties = new Set<string>();
  const coveredCities = new Set<string>();

  coveredZipCodes.forEach((coveredZipCode) => {
    const zipCodeFeature = zipCodeFeatures[coveredZipCode];
    if (zipCodeFeature) {
      const { cities, counties } = zipCodeFeature.properties;
      counties.forEach((county) => {
        coveredCounties.add(county);
      });
      cities.forEach((city) => {
        coveredCities.add(city);
      });
    }
  });

  return {
    zipCodeFeatures,
    coveredZipCodes,
    coveredCounties: Array.from(coveredCounties),
    coveredCities: Array.from(coveredCities),
  };
};

export default useRegionMapping;
