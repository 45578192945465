import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Init from "./components/init";
import MyCoverageAreas from "./pages/my-coverage-areas";

export const MY_COVERAGE_AREAS_PATH = "/my-coverage-areas/";

function AppRouter() {
  return (
    <Router>
      <Init />
      <Switch>
        <Redirect exact from="/" to={MY_COVERAGE_AREAS_PATH} />
        <Route path={MY_COVERAGE_AREAS_PATH}>
          <MyCoverageAreas />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default AppRouter;
