import React, { FC, useEffect, useRef } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import * as Leaflet from "leaflet";
import { Feature, GeometryObject } from "geojson";
import { useDispatch, useSelector } from "react-redux";
import coverage from "../ducks/coverage";
import { RootState } from "../ducks";
import { GeoPoliticalFeature } from "../types";
import { useStableCallback } from "../hooks/useStableCallback";
import MapController from "./map-controller";

const Map = () => {
  const { zipCodeFeatures: featureData } = useSelector(
    (state: RootState) => state.features
  );
  const { selectedZipCode } = useSelector((state: RootState) => state.controls);

  useEffect(() => {
    console.log("map is thinking...");
  });

  return (
    <MapContainer
      center={[37, -99]}
      zoom={4}
      scrollWheelZoom={true}
      className="h-full rounded-lg border-2 border-gray-200 shadow"
    >
      <MapController />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {Object.values(featureData)
        .filter((feature) => !!feature)
        .map((feature) => {
          return (
            <MyGeoJson
              key={feature.properties.zc}
              geoJson={feature}
              selectedZipCode={selectedZipCode}
            />
          );
        })}
    </MapContainer>
  );
};

const MyGeoJson: FC<{
  geoJson: GeoPoliticalFeature;
  selectedZipCode: string | undefined;
}> = ({ geoJson, selectedZipCode }) => {
  const dispatch = useDispatch();
  const { zipCodes } = useSelector((state: RootState) => state.coverage);
  const { freeSelectMode } = useSelector((state: RootState) => state.controls);
  // Inspired by https://github.com/PaulLeCam/react-leaflet/issues/332#issuecomment-480333796
  const geoJsonLayer = useRef<Leaflet.GeoJSON<any>>(null);
  const isTargetZipCode = geoJson.properties.zc === selectedZipCode;
  const isCovered = zipCodes.includes(geoJson.properties.zc);

  useEffect(() => {
    geoJsonLayer?.current?.clearLayers().addData(geoJson);
  }, [geoJson, selectedZipCode]);

  const handleMouseOver: Leaflet.LeafletMouseEventHandlerFn = (e) => {
    const layer = e.target;
    const zipCode = layer.feature.properties.zc;

    if (!freeSelectMode) {
      return;
    }
    if (!zipCodes.includes(zipCode)) {
      dispatch(coverage.actions.addZipCode(zipCode));
      layer.setStyle({ fillColor: "green" });
    }
  };

  const handleClick: Leaflet.LeafletMouseEventHandlerFn = (e) => {
    const layer = e.target;
    const { zc: zipCode } = layer.feature.properties;

    if (zipCodes.includes(zipCode)) {
      dispatch(coverage.actions.removeZipCode(zipCode));
    } else {
      dispatch(coverage.actions.addZipCode(zipCode));
    }
  };

  const stableHandleClick = useStableCallback(handleClick);
  const stableHandleMouseOver = useStableCallback(handleMouseOver);

  /**
   * This function is only ran once when the feature is mounted onto the map.
   */
  const handleEachFeature = (
    feature: Feature<GeometryObject, any>,
    layer: any
  ) => {
    layer.bindTooltip(feature.properties.zc);
    layer.on({
      click: stableHandleClick,
      mouseover: stableHandleMouseOver,
    });
  };

  const setStyle: Leaflet.StyleFunction<any> = () => {
    return {
      fillColor: isCovered ? "green" : "transparent",
      stroke: true,
      color: isTargetZipCode ? "red" : "#38529a",
      className: isTargetZipCode ? "target-zc" : "",
    };
  };

  return (
    <GeoJSON
      key={`${geoJson.properties.zc}-${isCovered ? "isCovered" : ""}`}
      ref={geoJsonLayer}
      data={geoJson}
      style={setStyle}
      onEachFeature={handleEachFeature}
    />
  );
};

export default Map;
